import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cardImg from "../assets/card des2.png"
import cardBack from "../assets/card back.png"
import { CusCareContext } from '../context/CusCareContext';
import { toast } from 'react-toastify';

const apiUrl = process.env.REACT_APP_API_URL
const Cards = () => {
    const {currentUser} = useSelector(state=>state.userAuth)
    const [linkedAccounts, setLinkedAccounts] = useState([])
    const {setCustomerCarePage, setCustomerCareMessage} = useContext(CusCareContext)
    useEffect(() => {
      async function fetchUserCards() {
        if (!currentUser?.email) return; // Ensure currentUser is defined
    
        try {
          const response = await axios.get(
            `${apiUrl}/users/user-cards/${currentUser.email}`
          );
          setLinkedAccounts(response.data.reverse());
        } catch (error) {
          toast.error("Error fetching Cards, reload page");
        }
      }
    
      fetchUserCards();
    }, [currentUser]); // Add currentUser as a dependency
    
      function handleCusOvMessage(){
        setCustomerCarePage(true)
        setCustomerCareMessage("link account")
      }
    return (
        <div className='z-20 w-full h-full left-0 md:absolute md:left-[10%] lg:left-[13%] md:w-[90%] lg:w-[87%] p-4'>
            <h1 className='font-xl font-semibold'>Cards</h1>
            <div className='flex flex-wrap gap-10 mt-4'>
                { 
                    linkedAccounts.map((acct, i)=>(
                        <div key={i} className='debitCard w-[300px] h-[187px] rounded-2xl'>
                            <div style={{backgroundImage: `url("${cardImg}")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}} className='card-front w-full h-full rounded-2xl shadow-soft cursor-pointer'> 
                              <p className='absolute left-[28px] top-4 tracking-[1.5px] text-[18px] flex items-center justify-center  text-white font-bold'>{acct?.card_type}</p>
                              <p className='absolute left-[28px] top-[105px] tracking-[1.5px] text-[18px] flex items-center justify-center  text-white font-bold'>
                                <span className='mr-6'>{acct?.card_number.toString().split("").slice(0, 4).join("")}</span>
                                <span className='mr-6'>{acct?.card_number.toString().split("").slice(4, 8).join("")}</span>
                                <span className='mr-6'>{acct?.card_number.toString().split("").slice(8, 12).join("")}</span>
                                <span className='mr-6'>{acct?.card_number.toString().split("").slice(12, 16).join("")}</span>
                                </p>
                                <p className='absolute left-[150px] text-[15px] top-[131px] text-white'>{`${acct?.expire_month}/${acct?.expire_year}`}</p>
                                <p className='absolute left-[28px] text-white bottom-2'>{acct?.cardholder_name}</p>
                            </div>
                            <div style={{backgroundImage: `url("${cardBack}")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}} className='card-back w-full h-full rounded-2xl cursor-pointer relative'> 
                             
                            </div>
                        </div>
                    ))
                }
                <div className='w-[300px] h-[187px] rounded-2xl cursor-pointer border-2 border-dashed border-gray-300  relative bg-lightgray flex items-center justify-center ' onClick={()=>handleCusOvMessage()}>
                  <span className='text-2xl text-gray-300'>+</span>
                </div>
            </div>
        </div>
    );
}

export default Cards;
