import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import CryptoTransaction from "../components/CryptoTransaction";
import { useDispatch, useSelector } from "react-redux";
import { BankContext } from "../context/BankContext";
import AdminLayout from "./AdminLayout";
import Footer from "../components/Footer";
import { ToastContainer } from "react-toastify";
import { CusCareContext } from "../context/CusCareContext";
import CustomerCareOverlay from "../components/CustomerCareOverlay";
import ConfirmPinForTransaction from "../components/ConfirmPinForTransaction";
import { refreshUser } from "../slice/authUserSlice";
import axios from "axios";
import Loader from "../components/Loader";
import TransactionReceipt from "../components/TransactionReceipt";

export default function AppLayout() {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const { isLoggedIn, currentUser } = useSelector((state) => state.userAuth);
  const { cryptoPage, confirmPinForAmount, isLoading, setIsloading, receiptPage, setReceiptPage } = useContext(BankContext);
  const { customerCarePage } = useContext(CusCareContext);
  const dispatch = useDispatch();
  function handleMousemove(e) {
    if (x !== e.pageX && y !== e.pageY) {
      setX(e.pageX);
      setY(e.pageY);
    }
  }
  
  
  if (!isLoggedIn) return <Navigate to="/login" />;
  if(currentUser?.is_admin) return <Navigate to="/admin" />

  return (
    <>
    {isLoading && <Loader />}
    <div
      onMouseMove={handleMousemove}
      className="h-full w-full relative overflow-hidden">
      <ToastContainer />
      <Outlet />
      <Sidebar />
      {cryptoPage && <CryptoTransaction />}
      {customerCarePage && <CustomerCareOverlay />}
      {confirmPinForAmount && <ConfirmPinForTransaction />}
      {receiptPage && <TransactionReceipt />}
      <Footer />
    </div>
    </>
  );
}
